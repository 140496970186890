import colors from "../../config/colors";

export default {
    container: {
        backgroundColor: colors.white, 
    }, 
    footer: {
        borderTop: 1, 
        borderColor: colors.grayExtraLigth
    },
    icon: {
        width: 60, 
        height: 60, 
        color: colors.medium, 
        mt: 8, 
        mb: 2
    }, 
    iconContainer: {
        display: "flex"
    }, 
    text: {
        fontSize: 22, 
        textAlign: 'center'
    }, 
    textWeb: {
        fontSize: 14,
        mx: 2
    }
}