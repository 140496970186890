import colors from "../../config/colors";

export default {
    button: {
        backgroundColor: colors.bluePrimary,
      },
    container: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        p: 4,
        width: window.innerWidth - 64
    },
    iconContainer: {
        display: "flex", 
        m: 4
    },
    icon: {
        color: colors.logout, 
        width: 60, 
        height: 60, 
    }, 
    text: {
        textAlign: "center", 
    }
}