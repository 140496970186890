import React from "react"
import ContentLoader from "react-content-loader"

const LoaderImage = (props) => {
    return (
    <ContentLoader 
        speed={2}
        width={232}
        height={232}
        viewBox="0 0 232 260"
        backgroundColor="#f3f3f3"
        foregroundColor="#e3e3e3"
        {...props}
    >
        <rect x="0" y="0" rx="3" ry="3" width="200" height="200" /> 
        {
            !props.onlyImage &&
            <>
                <rect x="0" y="210" rx="3" ry="3" width="200" height="18" /> 
            </>
        }
  </ContentLoader>
)}

export default LoaderImage
