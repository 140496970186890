import colors from "../../config/colors";

export default {
    container: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        p: 4,
    }, 
    inputArea: {
        width: window.innerWidth - 96,
        borderRadius: 8, 
        padding: 16, 
        marginTop: 16, 
        marginBottom: 16
    },
    title: {
        fontSize: 18, 
        margin: 16,
        fontWeight: "bold", 
        color: colors.medium 
    }, 
    star: (disable) => ({
        color: disable ? colors.disable : colors.star, 
        width: "20%", 
        height:"20%", 
    }),
    button: {
        backgroundColor: colors.bluePrimary, 
    },
    buttonCancel: {
        backgroundColor: colors.logout,
        marginRight: 4 
      },
      
}