import React, { useContext, useEffect, useState } from 'react';
import { Grid, Typography, Box, Link} from '@mui/material';
import { connect } from 'react-redux';

import AppSelect from '../components/Select';
import LanguageMapping from '../mappings/LanguageMapping';
import styles from './styles/HomeStyles'
import CategoryCard from '../components/CategoryCard'
import CategoriesActions from '../redux/CategoriesRedux'
import ImageActions from '../redux/ImageRedux'
import ApiContext from '../context/ApiContext';
import LoaderImage from '../components/LoaderImage';
import I18n from '../i18n/I18n';
import en from '../i18n/languages/en.json'
import es from '../i18n/languages/es.json'

const Home = ({ loading, getCategories, categories, getIcon, icon, iconBase64, loadingImage }) => {

    const apiContext = useContext(ApiContext)
    const [optionsModal, setOptionsModal] = useState(false)
    const [languangeChange, setLanguangeChange] = useState(false)
    const [language, setLanguage] = useState(localStorage.getItem("languageCache"))

    useEffect(() => {
        if (apiContext?.api) {
            getIcon(apiContext?.api)
            getCategories(apiContext?.api)
        }
    }, [])

    useEffect(() => {
        I18n.locale = language;
    
        I18n.fallbacks = true;
        I18n.translations = {
          en,
          es, 
        };
        if (languangeChange) {
            localStorage.setItem("languageCache", language)
            window.location.reload(false);
        }
        
      }, [language])
    

    return (
        <Grid sx={styles.container} >
            <Grid container  >
                <AppSelect data={LanguageMapping} width={window.innerWidth - 64} value={language} setValue={setLanguage} setLanguageChange={setLanguangeChange} />
                <Grid alignItems="center" justifyContent="center" container sx={styles.header} >
                    <Typography style={styles.title} >{I18n.t("title")}</Typography>
                    {
                        loadingImage 
                            ?
                                <LoaderImage onlyImage/>
                            :
                                <img 
                                    src={`data:image/jpeg;base64,${iconBase64}`} 
                                    width="200" 
                                    height="200" />
                    }
                </Grid>
            </Grid>
            <Grid container justifyContent="center" >
                {categories?.map((item, index) => (

                    <CategoryCard name={item?.nombre} icon={item?.icono} id={item?.id} />
                ))}
            </Grid>
            <Box sx={styles.footer} >
                <Grid justifyContent="center" alignItems="center" container >
                    <img src={require('../icono.png')} height="50" width="50" />
                    <Link underline="none" href="https://infiengineering.com" sx={styles.textWeb} >wwww.infiengineering.com</Link>
                </Grid>
            </Box>
        </Grid>
    )
}

const mapStateToProps = (state) => {
    return {
        error: state.category.error,
        categories: state.category.categories,
        loading: state.category.loading,
        loadingImage: state.image.loading,
        icon: state.image.icon,
        iconBase64: state.image.iconBase64,
    }
  }
  
  const mapDispatchToProps = (dispatch) => {
    return {
        getCategories: (apiHost) => dispatch(CategoriesActions.categoriesRequest(apiHost)),
        getIcon: (apiHost) => dispatch(ImageActions.iconRequest(apiHost))
    }
  }
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Home)