import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
    apiRequest: ['web'],
    apiSuccess: ['api'],
    apiFailure: ['error'],

})

export const GeneralTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
    api: null,
    loading: false,
    error: false

})

/* ------------- api Reducers ------------- */
export const apiRequest = (state, actions) => {
    return state.merge({ loading: true , error: false })
}

export const apiSuccess = (state, { api }) => {
    return state.merge({ api: api, loading: false })
}

export const apiFailure = (state, { error }) => {
    return state.merge({ api: null, loading: false , error: true })
}



/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
    [Types.API_REQUEST]: apiRequest,
    [Types.API_SUCCESS]: apiSuccess,
    [Types.API_FAILURE]: apiFailure,

})