import React, { useContext } from 'react';
import {Box, Typography, Grid} from '@mui/material';
import AdjustIcon from '@mui/icons-material/Adjust';
import { Link } from '@mui/material';

import styles from './styles/CategoryCardStyles'
import LoaderImage from './LoaderImage';
import { connect } from 'react-redux'
import IconMapping from '../mappings/IconMapping';
import ApiContext from '../context/ApiContext';


const CategoryCard  = ({ name, icon, image, loading, onClick, id }) => {

    const apiContext = useContext(ApiContext)

    const addHashBrowser = () => {
        return apiContext?.web?.replace("https://valoracionesclientesinfiengineering.com/", "https://valoracionesclientesinfiengineering.com/#/")
    }

    console.log(addHashBrowser(), " web aui");

    return (
        <Link href={ addHashBrowser() + "/opciones/?categoria=" + id} color="inherit" underline='none' >
            <Grid alignItems="center" justifyContent="center" sx={styles.container} textOverflow="ellipsis" >
                <Grid 
                    onClick={onClick}
                    alignItems="center" 
                    justifyContent="center" 
                    sx={styles.cardContainer}
                    textOverflow="ellipsis" >

                        {(icon && IconMapping.find(item => item?.icono == icon)) ? 
                                IconMapping.find(item => item?.icono == icon)?.IconMap
                            :

                            <AdjustIcon sx={styles.icon} />

                        }
                        {loading ? <LoaderImage/> : 
                        image && 
                            <img 
                                src="https://www.pngarts.com/files/9/Vector-Restaurant-PNG-Download-Image.png" 
                                width="120" 
                                height="120"
                                style={styles.image} /> }
                </Grid>
                
                <Typography style={styles.text} align="center" >{name}</Typography>
            </Grid>
        </Link>
    )
}

const mapStateToProps = (state) => {
    return {
        error: state.option.error,
        options: state.option.options,
        loading: state.option.loading,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(CategoryCard)