import React from 'react';
import { Grid, Typography, TextField } from '@mui/material';
import styles from './styles/HeaderStyles'
import I18n from '../i18n/I18n';

const Header = ({ title, search, setFilter }) => {

    return (
        <>
            <Grid xs={12} style={styles.container} justifyContent="center" alignItems="center" container >
                <Typography style={styles.title} >{title}</Typography>
            </Grid>
           { search && 
                <Grid xs={12} style={styles.container} justifyContent="center" alignItems="center" container >
                    <TextField id="outlined-basic" label={I18n.t("search")} variant="filled" style={styles.searchContainer} onChange={({target}) => setFilter(target.value)} />
                </Grid>}
        </>
            

    )
}

export default Header