export default {

    getEnterpriseApi: async (web) => {
        const body = {
            "web": web
        }
    
        return await 
            fetch(process.env.REACT_APP_GENERAL_HOST + "conseguirApiWeb", 
                {
                method: 'POST', 
                headers: new Headers ({
                    "Accept": 'application/json',
                    "Content-Type": "application/json"
                }), 
                body: JSON.stringify(body)
            })
                .then(response => { 
                    return response.json()
                })
                .then(response => {
                    if (response?.statusCode == 200) {
                        return response?.usuarioBuscado[0]
                    }
                   
                })
                .catch(error => {return {"error" : error}})
            }
}