import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
    categoriesRequest: ['apiHost'],
    categoriesSuccess: ['categories'],
    categoriesFailure: ['error'],

    getNameRequest: ['apiHost', 'id'],
    getNameSuccess: ['name'],
    getNameFailure: ['error'],

})

export const CategoriesTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
    categories: [],
    name: null,
    loading: false,
    error: false

})

/* ------------- categories Reducers ------------- */
export const categoriesRequest = (state, actions) => {
    return state.merge({ loading: true , error: false })
}

export const categoriesSuccess = (state, { categories }) => {
    return state.merge({ categories: categories, loading: false })
}

export const categoriesFailure = (state, { error }) => {
    return state.merge({ categories: null, loading: false , error: true })
}


/* ------------- getName Reducers ------------- */
export const getNameRequest = (state, actions) => {
    return state.merge({ loading: true , error: false })
}

export const getNameSuccess = (state, { name }) => {
    return state.merge({ name: name, loading: false })
}

export const getNameFailure = (state, { error }) => {
    return state.merge({ getName: null, loading: false , error: true })
}


/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
    [Types.CATEGORIES_REQUEST]: categoriesRequest,
    [Types.CATEGORIES_SUCCESS]: categoriesSuccess,
    [Types.CATEGORIES_FAILURE]: categoriesFailure,

    [Types.GET_NAME_REQUEST]: getNameRequest,
    [Types.GET_NAME_SUCCESS]: getNameSuccess,
    [Types.GET_NAME_FAILURE]: getNameFailure,


})