export default  {
    menuLabel: {
        ml: 2, 
        fontSize: 18
    },
    menuItem: {
        p2: 8, 
        columns: 2
    },
    selectContainer: (width) => ({
        width: width, 
        borderRadius: 2,
    }), 
    
}