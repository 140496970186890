export default {

    addReviewApi: async (apiHost, rating, comment, id) => {
        const body = {
            "puntuacion" : rating, 
            "comentario": comment, 
            "opcionCategoriaValoracion": id          
        }
        console.log(body, " body");
        return await fetch(apiHost + 'agregarValoracion', { 
            method: 'POST', 
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body : JSON.stringify(body)}) 
            .then(response => {
                console.log(response);
                return response.json()
            })
            .then(response => {
                return response
            })
            .catch(err => console.log(err))
        }
}