import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
    addReviewRequest: ['apiHost', 'rating', 'comment', 'id'],
    addReviewSuccess: null,
    addReviewFailure: ['error'],

})

export const ReviewTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
    loading: false,
    error: false

})

/* ------------- addReview Reducers ------------- */
export const addReviewRequest = (state, actions) => {
    return state.merge({ loading: true , error: false })
}

export const addReviewSuccess = (state, {  }) => {
    return state.merge({ loading: false, error: false })
}

export const addReviewFailure = (state, { error }) => {
    return state.merge({ loading: false , error: true })
}



/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
    [Types.ADD_REVIEW_REQUEST]: addReviewRequest,
    [Types.ADD_REVIEW_SUCCESS]: addReviewSuccess,
    [Types.ADD_REVIEW_FAILURE]: addReviewFailure,

})