import { call, put } from 'redux-saga/effects'
import imageApi from '../api/ImageApi'
import OptionActions from '../redux/OptionsRedux'

export function* getOptions(api, { apiHost, option, filter }) {
    try {
        const data = yield call(api.getOptionsApi, apiHost, option, filter)
        const dataWithImage = []
        
        for (let i = 0; i < data?.length; i++) {
            const image = yield call(imageApi.decryptImageApi, data[i]?.imagen)
            const imageDecrypted = image?.replace(image.substring(0, process.env.REACT_APP_RAW_BYTES), "")

            const optionData = {
                ...data[i], 
                "imagenBase64" : imageDecrypted[1] == "9" ? imageDecrypted : "no"
            }

            dataWithImage.push(optionData)
        }


        if (Array.isArray(data)) {
            yield put(OptionActions.optionsSuccess(dataWithImage))
        }
        else {
            const error = 'Error get options sagas'
            console.log(error)
            yield put(OptionActions.optionsFailure(error))
        }
    }
    catch (error) {
        console.log(error.message)
        yield put(OptionActions.optionsFailure(error.message))
    }
}

