import { call, put } from 'redux-saga/effects'
import ImageActions from '../redux/ImageRedux'

export function* getIcon(api, { apiHost }) {
    try {
        const data = yield call(api.getIconApi, apiHost)

        if (data) {
            yield put(ImageActions.iconSuccess(data))
            yield put(ImageActions.decryptImageRequest(data))
        }
        else {
            const error = 'Error get icon sagas'
            console.log(error)
            yield put(ImageActions.iconFailure(error))
        }
    }
    catch (error) {
        console.log(error.message)
        yield put(ImageActions.iconFailure(error.message))
    }
}


export function* decryptImage(api, { url }) {
    try {
        const data = yield call(api.decryptImageApi, url)

        if (data) {            
            yield put(ImageActions.decryptImageSuccess(data?.replace(data.substring(0, process.env.REACT_APP_RAW_BYTES), "")))
        }
        else {
            const error = 'Error decrypt image sagas'
            console.log(error)
            yield put(ImageActions.decryptImageFailure(error))
        }
    }
    catch (error) {
        console.log(error.message)
        yield put(ImageActions.decryptImageFailure(error.message))
    }
}


