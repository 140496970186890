import React, { useContext, useEffect, useState } from 'react';
import { Box, Grid, Link, Typography, } from '@mui/material';
import { connect } from 'react-redux'
import { useSearchParams } from 'react-router-dom';
import SearchOffIcon from '@mui/icons-material/SearchOff';

import I18n from '../i18n/I18n';
import Header from '../components/Header';
import OptionCard from '../components/OptionCard';
import styles from './styles/OptionsScreenStyles'
import OptionsActions from '../redux/OptionsRedux'
import CategoriesActions from '../redux/CategoriesRedux'
import ReviewActions from '../redux/ReviewRedux'
import LoaderImage from '../components/LoaderImage'
import AppModal from '../components/AppModal';
import ApiContext from '../context/ApiContext';
import SuccessModal from '../components/SuccessModal';
import ErrorModal from '../components/ErrorModal';
import en from '../i18n/languages/en.json'
import es from '../i18n/languages/es.json'
import logo from '../icono.png'

const OptionsScreen = ({ loading, getOptions, options, addReview, error, getName, name }) => {

    const [visible, setVisible] = useState(false)
    const [language, setLanguage] = useState(localStorage.getItem("languageCache"))
    const [pressedModal, setPressedModal] = useState(false)
    const [visibleSuccess, setVisibleSuccess] = useState(false)
    const [visibleError, setVisibleError] = useState(false)
    const apiContext = useContext(ApiContext)
    const [searchParams, setSearchParams] = useSearchParams();
    const [filter, setFilter] = useState("")
    const [comment, setComment] = useState("")
    const [rating, setRating] = useState(0)
    const [optionSelected, setOptionSelected] = useState()

    useEffect(() => {
        if (apiContext?.api) {

            getOptions(apiContext?.api, searchParams.get("categoria"), filter)
            getName(apiContext?.api, searchParams.get("categoria"))
        }
    }, [filter])

    useEffect(() => {
        I18n.locale = language;
    
        I18n.fallbacks = true;
        I18n.translations = {
          en,
          es, 
        };
        
      }, [language])
    

    const onPressConfirmModal = () => {

        addReview(apiContext?.api, rating, comment, optionSelected?.idOpcionCategoriaValoracion )
        setPressedModal(true)
    }

    return ( 
        <>
            <Header title={name} search setFilter={setFilter} />
            <SuccessModal 
                visible={visibleSuccess} 
                setVisible={setVisibleSuccess} 
                title={I18n.t("success_valoration")} />
            <ErrorModal 
                visible={visibleError} 
                setVisible={setVisibleError} 
                title={I18n.t("error_valoration")}  />
            <AppModal 
                loading={loading}
                visible={visible}
                error={error}
                pressed={pressedModal}
                setPressed={setPressedModal}
                setVisibleSuccess={setVisibleSuccess}
                setVisibleError={setVisibleError}
                setVisible={setVisible}
                stars
                setComment={setComment}
                setRating={setRating}
                onPressConfirmModal={onPressConfirmModal}
                button={I18n.t("send")}
                buttonCancel={I18n.t("back")}
                title={I18n.t("valoration_tittle_1") + " " + I18n.t("valoration_tittle_2")} 
                placeholder={I18n.t("write_text")} />
                <Grid container justifyContent="center" style={styles.container} >
                    {
                        loading ? 
                            [1, 2, 3, 4, 5].map(() => (
                                <LoaderImage/>
                            )) 
                        :
                        (   options?.length == 0 ?
                            <Grid justifyContent="center" alignItems="center" >
                                <Grid sx={styles.iconContainer} justifyContent="center" alignItems="center"  >
                                <SearchOffIcon sx={styles.icon} />
                                </Grid>
                                <Typography sx={styles.text} >{I18n.t("no_data")}</Typography>
                            </Grid>
                        :
                            options?.map((item, index) => (
                                <OptionCard name={item.nombre} image={item?.imagenBase64} onClick={() => {
                                    setOptionSelected(item)
                                    setVisible(true)}} />
                            )))
                    }


                </Grid>
                <Box sx={styles.footer} >
                    <Grid justifyContent="center" alignItems="center" container >
                        <img src={require('../icono.png')} height="50" width="50" />
                        <Link underline="none" href="https://infiengineering.com" sx={styles.textWeb} >wwww.infiengineering.com</Link>
                    </Grid>
                </Box>
                
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        error: state.option.error || state.review.error,
        options: state.option.options,
        loading: state.option.loading || state.category.loading,
        name: state.category.name
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getOptions: (apiHost, option, filter) => dispatch(OptionsActions.optionsRequest(apiHost, option, filter)),
        getName: (apiHost, id) => dispatch(CategoriesActions.getNameRequest(apiHost, id)),
        addReview: (apiHost, rating, comment, id) => dispatch(ReviewActions.addReviewRequest(apiHost, rating, comment, id)),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(OptionsScreen)
