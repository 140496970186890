import { CircularProgress } from '@mui/material';
import React from 'react';
import styles from './styles/LoadingStyles'

const Loading = () => {

    return (
        <CircularProgress sx={styles.loading} /> 
    )
}

export default Loading