import colors from "../../config/colors"

export default {
    cardContainer: {
        justifyContent: 'center', 
        alignItems: 'center',
    }, 
    container: { 
        py: 2,
        px: 1,
        maxWidth: 135,
    },
    icon: {
        width: 120, 
        height: 120, 
        color: colors.white, 
        backgroundColor: colors.grayLight,
    },
    image: {
    }, 
    text: {
        fontSize: 16, 
        textAlign: "center",
        mb: 2, 
    }
}