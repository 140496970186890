import { call, put } from 'redux-saga/effects'
import ReviewActions from '../redux/ReviewRedux'

export function* addReview(api, { apiHost, rating, comment, id }) {
    try {
        const data = yield call(api.addReviewApi, apiHost, rating, comment, id)
        console.log(data, " data")
        if (data?.affectedRows == 1) {
            yield put(ReviewActions.addReviewSuccess(data))
        }
        else {
            const error = 'Error get review sagas'
            console.log(error)
            yield put(ReviewActions.addReviewFailure(error))
        }
    }
    catch (error) {
        console.log(error.message)
        yield put(ReviewActions.addReviewFailure(error.message))
    }
}

