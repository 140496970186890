import React from 'react'

export default [
    {
        "label": "Español", 
        "value": "es-ES", 
        "flag": 'ES'
    }, 
    {
        "label": "English", 
        "value": "en-GB", 
        "flag": 'GB'
    }, 
    

]