export default {

    getOptionsApi: async (apiHost, option, filter) => {
        const body = {
            opcion : option, 
            "filtro" : filter || filter != "" ? filter : null
        }
    
        return await 
            fetch(apiHost + 'obtenerOpciones', { 
                method: 'POST', 
                headers: new Headers ({
                    "Accept": 'application/json',
                    "Content-Type": "application/json"
                }), 
                body : JSON.stringify(body)}) 
                .then(response => { 
                    return response.json()
                })
                .then(response => {
                    return response
                })
                .catch(err => console.log(err))
            }
}