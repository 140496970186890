export default {

    getCategoriesApi: async (apiHost) => {
        return await 
            fetch(apiHost + 'obtenerCategoriasAdquiridas', {  
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                }}) 
            .then(response => {
                return response.json()
            })
            .then(response => {
                return response
            })
            .catch(error => console.log(error))
    }, 

    getNameApi: async (apiHost, id) => {

        const body = {
            "id" : id       
        }

        return await 
            fetch(apiHost + 'obtenerCategoriaNombre', {  
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body : JSON.stringify(body)}) 
            .then(response => {
                return response.json()
            })
            .then(response => {
                return response?.data
            })
            .catch(error => console.log(error))
    }
}