import HomeIcon from '@mui/icons-material/Home';
import FastfoodIcon from '@mui/icons-material/Fastfood';
import GroupIcon from '@mui/icons-material/Group';

import colors from '../config/colors';


const stylesIcon = {
    width: 60, 
    height: 60, 
    color: colors.icon, 
}

export default [
    {
        nombre: 'General', 
        icono: 'home', 
        IconMap: <HomeIcon sx={stylesIcon} />
    },
    {
        nombre: 'Comida', 
        icono: 'food', 
        IconMap: <FastfoodIcon sx={stylesIcon}  />
    },
    {
        nombre: 'Personal', 
        icono: 'account-group', 
        IconMap: <GroupIcon sx={stylesIcon}  />

    }
]