import colors from "../../config/colors";

export default {
    container: {
        backgroundColor: colors.bluePrimary, 
    }, 
    searchContainer: {
        borderRadius: 16, 
        backgroundColor: colors.light, 
        width: '75%', 
        margin: 16
    },
    title: {
        fontSize: 25, 
        color: colors.white, 
        padding: 16
    }
}