export default {
    primary: "#fc5c65",
    secondary: "#4ecdc4",
    black: "#000",
    white: "#fff",
    medium: "#6e6969",
    light: "#F4F4F4",
    dark: "#0c0c0c",
    danger: "#ff5252",
    login : '#046186', 
    bluePrimary: '#046186', 
    logout: '#BA0A1F', 
    grayLight: '#A2A2A2',
    grayExtraLigth: '#D3D3D3',
    greyIcon: '#ACACAC', 
    disable: '#CBCCCD', 
    star: '#FFC100', 
    icon: "#00BFD3", 
    success: '#017D06'
  };