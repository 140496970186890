import React from 'react';
import { Grid, Modal, Typography, Button } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import styles from './styles/SuccessModalStyles'
import I18n from '../i18n/I18n'

function SuccessModal({ visible, title, setVisible }) {
  return (
    <Modal open={visible} >
        <Grid justifyContent="center" alignItems="center"  sx={styles.container}>
            <Grid justifyContent="center" alignItems="center" sx={styles.iconContainer} >
                <CheckCircleIcon sx={styles.icon} /> 
            </Grid>
            <Typography sx={styles.text}>{title}</Typography>
            <Grid justifyContent="center" alignItems="center" sx={styles.iconContainer} >
                <Button variant="contained" sx={styles.button} onClick={() => setVisible(false)}>{I18n.t("back")}</Button>
            </Grid>
        </Grid>
    </Modal>
  );
}



export default SuccessModal;