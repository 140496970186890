import React, { useEffect, useState } from 'react';
import { TextareaAutosize, Typography, Modal, Box, Grid, Button } from '@mui/material'

import styles from './styles/AppModalStyles'
import StarIcon from '@mui/icons-material/Star';
import Loading from './Loading'

const AppModal = ({ title, placeholder, buttonCancel, button, stars, visible, setVisible, onPressConfirmModal, setComment, setRating, 
                    loading, pressed, setPressed, setVisibleSuccess, setVisibleError, error }) => {

    const [starsPressed, setStarsPressed] = useState(0)

    useEffect(() => {
        if (pressed && !loading && !error) {
            setStarsPressed(0)
            setPressed(false)
            setVisible(false)
            setVisibleSuccess(true)
        } else if (pressed && !loading && error) {
            setStarsPressed(0)
            setPressed(false)
            setVisible(false)
            setVisibleError(true)
        }
    }, [loading, pressed])

    useEffect(() => {
        setRating(starsPressed)
    }, [starsPressed])

    const onPressCancel = () => {
        setVisible(false)
    }

    return (
        
        <div>
            <Modal
            open={visible}
            aria-labelledby="modal-modal-title">
                <Box sx={styles.container}>
                    <Typography id="modal-modal-title" textAlign="center" style={styles.title} >
                        {title}
                    </Typography>
                    {stars && <Grid sx={styles.starContainer} justifyContent="center" alignItems="center" >
                        {[...Array(5)].map((star, index) => {
                            index += 1;
                            return (
                                <StarIcon sx={styles.star((index > starsPressed))} onClick={() => index == starsPressed ? setStarsPressed(0)  : setStarsPressed(index)}  />
                            );
                        })}
                    </Grid>}
                    {loading && <Loading/>}
                    <TextareaAutosize 
                        onChange={({ target }) => setComment(target.value)}
                        minRows={8}
                        placeholder={placeholder}
                        style={styles.inputArea}  />
                    <Grid container justifyContent="center" alignItems="center" >
                        {buttonCancel && <Button variant="contained" sx={styles.buttonCancel} onClick={() => onPressCancel()} >{buttonCancel}</Button>}
                        {button && <Button variant="contained" sx={styles.button} onClick={() => onPressConfirmModal()}>{button}</Button>}
                    </Grid>
                </Box>
            </Modal>
      </div>
    )
}

export default AppModal