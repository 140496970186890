import colors from '../../config/colors'

export default {
    title: {
        fontSize: 23,
        textAlign: "center", 
        marginBottom: 32, 
        fontWeight: "bold", 
        color: colors.medium
    }, 
    footer: {
        borderTop: 1, 
        borderColor: colors.grayExtraLigth, 
        mt: 4,
    },
    header: {
        my: 8 
    }, 
    container: { 
        m: 2 
    }, 
    textWeb: {
        fontSize: 14,
        mx: 2
    }
}