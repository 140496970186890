import { call, put } from 'redux-saga/effects'
import GeneralActions from '../redux/GeneralRedux'

import config from '../config/config'

export function* getApi(api, { web }) {
    try {
        const data = yield call(api.getEnterpriseApi, web)

        if (data?.api) {
            localStorage.setItem("web", "#/" + web)
            localStorage.setItem("token", config.encryptApi(data?.api))
            yield put(GeneralActions.apiSuccess(data?.api))
        }
        else {
            const error = 'Error get api sagas'
            console.log(error)
            yield put(GeneralActions.apiFailure(error))
        }
    }
    catch (error) {
        console.log(error.message)
        yield put(GeneralActions.apiFailure(error.message))
    }
}

