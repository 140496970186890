import { call, put } from 'redux-saga/effects'
import CategoriesActions from '../redux/CategoriesRedux'

export function* getCategories(api, { apiHost }) {
    try {
        const data = yield call(api.getCategoriesApi, apiHost)

        if (Array.isArray(data)) {
            yield put(CategoriesActions.categoriesSuccess(data))
        }
        else {
            const error = 'Error get categories sagas'
            console.log(error)
            yield put(CategoriesActions.categoriesFailure(error))
        }
    }
    catch (error) {
        console.log(error.message)
        yield put(CategoriesActions.categoriesFailure(error.message))
    }
}


export function* getName(api, { apiHost, id }) {
    try {
        console.log(id, " id sagas antes de api");
        const data = yield call(api.getNameApi, apiHost, id)
        
        console.log(data, " sagas");

        if (Array.isArray(data)) {
            yield put(CategoriesActions.getNameSuccess(data[0]?.nombre))
        }
        else {
            const error = 'Error get name sagas'
            console.log(error)
            yield put(CategoriesActions.getNameFailure(error))
        }
    }
    catch (error) {
        console.log(error.message)
        yield put(CategoriesActions.getNameFailure(error.message))
    }
}



