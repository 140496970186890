
import { useState, useEffect } from 'react';
import { HashRouter, Routes, Route } from "react-router-dom";
import { connect } from 'react-redux'

import Home from './screens/Home';
import ApiContext from './context/ApiContext';
import Loading from './components/Loading';
import GeneralActions from './redux/GeneralRedux'
import OptionsScreen from './screens/OptionsScreen'
import config from './config/config';

function App({ getApi, apiEnterprise }) {

  const [api, setApi] = useState()
  const [web, setWeb] = useState()

  useEffect(() => {
    if (localStorage.getItem("languageCache") == null) {

      localStorage.setItem("languageCache", "es-ES")
    }
    const webCache = localStorage.getItem("web")
    const apiCache = config.decryptApi(localStorage.getItem("token"))

    if (webCache && apiCache) {
      setWeb(webCache)
      setApi(apiCache)
    } else {
      setWeb(getShortWeb())
      if (web && !api) {
        getApi(web.substring(2, web.length))
      }
  
      if (apiEnterprise) {
        setApi(apiEnterprise)
      }
    }
  }, [web, apiEnterprise])

  const getShortWeb = () => {
    return "#" + window.location.href.substring(window.location.href.lastIndexOf("/"), window.location.href.length)
  }

  return (
    <HashRouter >
        <ApiContext.Provider value={{ api, setApi, web, setWeb }} >
          {
            (web && api) ? 
              <Routes>
                <Route path={web?.substring(2, web.length)} element={<Home />} />
                <Route path={ web?.substring(2, web.length) + "/opciones"} element={<OptionsScreen />} />
              </Routes>
              :
              <Loading/>
          }
        </ApiContext.Provider>
    </HashRouter>
  );
}


const mapStateToProps = (state) => {
  return {
      error: state.general.error,
      apiEnterprise: state.general.api,
      loading: state.general.loading,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
      getApi: (web) => dispatch(GeneralActions.apiRequest(web))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(App)
