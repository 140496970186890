import React, { useContext, useEffect } from 'react';
import {Box, Typography, Grid} from '@mui/material';
import AdjustIcon from '@mui/icons-material/Adjust';
import { Link } from '@mui/material';

import styles from './styles/OptionCardStyles'
import LoaderImage from './LoaderImage';
import { connect } from 'react-redux'
import ImageActions from '../redux/ImageRedux'
import CameraAltIcon from '@mui/icons-material/CameraAlt';

const OptionCard  = ({ name, icon, image, loading, onClick, decryptImage }) => {

    return (
            <Grid alignItems="center" justifyContent="center" sx={styles.container} textOverflow="ellipsis" >
                <Grid 
                    onClick={onClick}
                    alignItems="center" 
                    justifyContent="center" 
                    sx={styles.cardContainer}
                    textOverflow="ellipsis" >
                        {loading ? <LoaderImage/> : 
                        image != "no" ? 
                            <img 
                                src={"data:image/png;base64, " + image}
                                width="120" 
                                height="120"
                                style={styles.image} /> 
                            :
                            <CameraAltIcon style={styles.icon} />
                        }
                </Grid>
                
                <Typography style={styles.text} align="center" >{name}</Typography>
            </Grid>
    )
}

const mapStateToProps = (state) => {
    return {
        error: state.option.error,
        options: state.option.options,
        loading: state.option.loading,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        decryptImage: (url) => dispatch(ImageActions.decryptImageRequest(url))
        
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(OptionCard)