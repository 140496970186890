import { all, takeLatest } from 'redux-saga/effects'

import { GeneralTypes } from '../redux/GeneralRedux'
import { OptionTypes } from '../redux/OptionsRedux'
import { CategoriesTypes } from '../redux/CategoriesRedux'
import { ImageTypes } from '../redux/ImageRedux'

import { getApi } from './GeneralSagas'
import { getOptions } from './OptionsSagas'
import { getCategories, getName } from './CategoriesSagas'
import { decryptImage, getIcon } from './ImageSagas'
import { addReview } from './ReviewSagas'

import generalApi from '../api/GeneralApi'
import categoriesApi from '../api/CategoriesApi'
import imageApi from '../api/ImageApi'
import optionsApi from '../api/OptionsApi'
import reviewApi from '../api/ReviewApi'
import { ReviewTypes } from '../redux/ReviewRedux'

export default function* root() {
    yield all([
        takeLatest(OptionTypes.OPTIONS_REQUEST, getOptions, optionsApi),
        
        takeLatest(ReviewTypes.ADD_REVIEW_REQUEST, addReview, reviewApi),
        
        takeLatest(GeneralTypes.API_REQUEST, getApi, generalApi),
        
        takeLatest(CategoriesTypes.CATEGORIES_REQUEST, getCategories, categoriesApi),
        takeLatest(CategoriesTypes.GET_NAME_REQUEST, getName, categoriesApi),
        
        takeLatest(ImageTypes.ICON_REQUEST, getIcon, imageApi),
        takeLatest(ImageTypes.DECRYPT_IMAGE_REQUEST, decryptImage , imageApi),
    ])
}
