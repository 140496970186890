import { combineReducers } from 'redux'
import configureStore from './CreateStore'
import rootSaga from '../sagas/'

const combinedReducers = combineReducers({
  option: require('./OptionsRedux').reducer,
  general: require('./GeneralRedux').reducer,
  category: require('./CategoriesRedux').reducer,
  image: require('./ImageRedux').reducer,
  review: require('./ReviewRedux').reducer,

})

export const reducers = (state, action) => {

  return combinedReducers(state, action)
}

export default () => {
  let finalReducers = reducers

  let { store, sagasManager, sagaMiddleware } = configureStore(finalReducers, rootSaga)

  if (module.hot) {
    module.hot.accept(() => {
      const nextRootReducer = require('./').reducers
      store.replaceReducer(nextRootReducer)

      const newYieldedSagas = require('../sagas').default
      sagasManager.cancel()
      sagasManager.done.then(() => {
        sagasManager = sagaMiddleware(newYieldedSagas)
      })
    })
  }

  return store
}
