
export default {
    encryptApi : (api) => {
        const httpToken =  api.replace("https://", process.env.REACT_APP_TOKEN_HTTP)

        return httpToken.replace(".execute-api.us-east-2.amazonaws.com/", process.env.REACT_APP_TOKEN_AWS)
    }, 

    decryptApi: (api) => {
        const httpToken =  api?.replace(process.env.REACT_APP_TOKEN_HTTP, "https://")

        return httpToken?.replace(process.env.REACT_APP_TOKEN_AWS, ".execute-api.us-east-2.amazonaws.com/")
    }
}