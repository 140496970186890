import { MenuItem, Select, Typography, InputLabel, FormControl, Box, Grid } from '@mui/material';
import React, { useState } from 'react';
import Flag from 'react-world-flags';
import styles from './styles/SelectStyles'
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import LanguageMapping from '../mappings/LanguageMapping';

const AppSelect = ({ width, data, value, setValue, setLanguageChange }) => {

    return (
        <FormControl>
            <Select
                sx={styles.selectContainer(width)}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={value}
                onChange={({ target }) => {
                    setLanguageChange(true)
                    setValue(target?.value)}
                } >
                    { data?.map((item, index) => (
                        <MenuItem value={item?.value} sx={styles.menuItem}  >
                            <Grid alignItems="center" container>
                                { item?.flag && <Flag code={item?.flag} height={40} width={40} />}
                                <Typography sx={styles.menuLabel} >{item?.label}</Typography>
                            </Grid>
                        </MenuItem>            
                    ))}
            </Select>
        </FormControl>
    )
}

export default AppSelect