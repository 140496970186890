export default {

    getIconApi: async (apiHost) => {
    
        return await 
            fetch( apiHost + 'obtenerIcono', {  
                method: 'GET',
                headers: new Headers ({
                    "Accept": 'application/json',
                    "Content-Type": "application/json"
                })
            }) 
                .then(response => {
                    return response.json()
                })
                .then(response => {
                    if (response?.length && response[0]?.icono) {
                        return response[0]?.icono
                    }
                })
    }, 

    decryptImageApi : async (url) => {
        
        return await 
            fetch(url, { 
                method: 'GET', 
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                }}) 
                .then(response => {
                    return response.text()
                })
                .then(response => { 
                    return response
                })
                .catch(err => {return err})
    }, 
}