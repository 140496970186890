import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
    iconRequest: ['apiHost'],
    iconSuccess: ['icon'],
    iconFailure: ['error'],

    decryptImageRequest: ['url'],
    decryptImageSuccess: ['iconBase64'],
    decryptImageFailure: ['error'],

})

export const ImageTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
    icon: null,
    iconBase64: null,
    loading: false,
    error: false

})

/* ------------- icon Reducers ------------- */
export const iconRequest = (state, actions) => {
    return state.merge({ loading: true , error: false })
}

export const iconSuccess = (state, { icon }) => {
    return state.merge({ icon: icon, loading: false })
}

export const iconFailure = (state, { error }) => {
    return state.merge({ icon: null, loading: false , error: true })
}


/* ------------- decryptImage Reducers ------------- */
export const decryptImageRequest = (state, actions) => {
    return state.merge({ loading: true , error: false })
}

export const decryptImageSuccess = (state, { iconBase64 }) => {
    return state.merge({ iconBase64: iconBase64, loading: false })
}

export const decryptImageFailure = (state, { error }) => {
    return state.merge({ iconBase64: null, loading: false , error: true })
}



/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
    [Types.ICON_REQUEST]: iconRequest,
    [Types.ICON_SUCCESS]: iconSuccess,
    [Types.ICON_FAILURE]: iconFailure,

    [Types.DECRYPT_IMAGE_REQUEST]: decryptImageRequest,
    [Types.DECRYPT_IMAGE_SUCCESS]: decryptImageSuccess,
    [Types.DECRYPT_IMAGE_FAILURE]: decryptImageFailure,

})