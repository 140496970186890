import colors from "../../config/colors";

export default {
    loading : {
        color: colors.bluePrimary, 
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    }
}