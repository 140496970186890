import colors from "../../config/colors"

export default {
    cardContainer: {
        justifyContent: 'center', 
        alignItems: 'center',
    }, 
    container: {
        border: 1, 
        borderRadius: 120,
        px: 4, 
        py: 2,
        mx: 2, 
        my: 2, 
        maxWidth: 135,
    },
    icon: {
        width: 60, 
        height: 60, 
        color: colors.icon, 
    },
    image: {
        padding: 8, 
    }, 
    text: {
        fontSize: 16, 
        textAlign: "center",
        mb: 2, 
    }
}