import React from 'react';
import { Grid, Modal, Typography, Button } from '@mui/material';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';

import styles from './styles/ErrorModalStyles'

function ErrorModal({ visible, title, setVisible }) {
  return (
    <Modal open={visible} >
        <Grid justifyContent="center" alignItems="center"  sx={styles.container}>
            <Grid justifyContent="center" alignItems="center" sx={styles.iconContainer} >
                <ReportGmailerrorredIcon sx={styles.icon} /> 
            </Grid>
            <Typography sx={styles.text}>{title}</Typography>
            <Grid justifyContent="center" alignItems="center" sx={styles.iconContainer} >
                <Button variant="contained" sx={styles.button} onClick={() => setVisible(false)}>Atrás</Button>
            </Grid>
        </Grid>
    </Modal>
  );
}



export default ErrorModal;