import I18n from "i18n-js";

import en from "./languages/en.json";
import es from "./languages/es.json";


  I18n.fallbacks = true;
  I18n.translations = {
    en,
    es, 
  };


export default I18n;


