import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
    optionsRequest: ['apiHost', 'option', 'filter'],
    optionsSuccess: ['options'],
    optionsFailure: ['error'],

})

export const OptionTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
    options: [],
    loading: false,
    error: false

})

/* ------------- options Reducers ------------- */
export const optionsRequest = (state, actions) => {
    return state.merge({ loading: true , error: false })
}

export const optionsSuccess = (state, { options }) => {
    return state.merge({ options: options, loading: false })
}

export const optionsFailure = (state, { error }) => {
    return state.merge({ options: null, loading: false , error: true })
}



/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
    [Types.OPTIONS_REQUEST]: optionsRequest,
    [Types.OPTIONS_SUCCESS]: optionsSuccess,
    [Types.OPTIONS_FAILURE]: optionsFailure,

})